

import i18n from '@/vueI18n'

export default {
    props: {
        discount: Object
    },
    setup() {
        function namePresenter(value) {
            return this.isRTL() ? value['ar'] : value['en']
        }
        return {
            namePresenter
        }

    },

    computed: {
        isDark() {
            return this.$q.dark.isActive
        },
        isRTL() {
            return i18n.locale == "ar"
        }
    }
}



